import React, { useState, useEffect, useContext } from "react";
import GenericTable, {
  DataTableInterface, FilterOpts,
} from "../../../components/misc/GenericTable";
import useServerProduct from "../../../api/useServerProducts";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { translateMeasure, translateOperation } from "../../../utils/translate";
import Paginate from "../../../components/misc/Paginate";
import MovementsTypeBadge from "../../../components/misc/badges/MovementsTypeBadge";
import Modal from "../../../components/modals/GenericModal";
import DetailMovement from "../../areas/stock/movements/DetailMovement";
import { DetailProductContext } from "../DetailProductContainer";
import ScrollTypeFilter from "../../../components/misc/ScrollTypeFilter";
import { useAppSelector } from "../../../store/hooks";
import { BasicType, SelectInterface } from "../../../interfaces/InterfacesLocal";
import { FilterOptions } from "../../../components/misc/FiltersComponent";

const Opperations = () => {
  const { product } = useContext(DetailProductContext);
  const { areas } = useAppSelector((state) => state.nomenclator);
  const { getOperations, allOperations, paginate, isLoading } =
    useServerProduct();
  const defaultFilter = { productId: product?.id ?? null, page: 1 }
  const [filter, setFilter] = useState<
    Record<string, string | number | boolean | null>
  >(defaultFilter);
  const [modalMovement, setModalMovement] = useState<{
    movementId: number | null;
    modalState: boolean;
  }>({ movementId: null, modalState: false });

  useEffect(() => {
    getOperations(filter);
  }, [filter]);

  //Data for filters -----------------------------------------------------------------------------------
  const typesDisplay: SelectInterface[] = [];
  const types: string[] = ["ENTRY", "MOVEMENT", "OUT", "TRANSFORMATION", "SALE"];
  types.forEach((item) =>
    typesDisplay.push({ id: item, name: translateOperation(item) })
  );

  //------------------------------------------------------------------------------------

  //Data for GenericTable -----------------------------------------------------------------
  const tableTitles = ["Operación", "Cantidad", "Fecha", "Usuario"];
  if(product?.type === "VARIATION") tableTitles.unshift("Variación")
  const tableData: DataTableInterface[] = [];
  allOperations.map((items) =>{
    if(product?.type === "VARIATION"){
      tableData.push({
        rowId: items.id,
        deletedRow: items.removedOperationId !== null,
        payload: {
          "Variación":items.variation?.name,
          Operación: <MovementsTypeBadge operation={items.operation} />,
          Cantidad: `${items.quantity} ${translateMeasure(
            items.product.measure
          )}`,
          Fecha: `${moment(
            items.createdAt
          ).format("DD/MM/YYYY hh:mm A")}`,
          Usuario: items.movedBy ? (
            items.movedBy.displayName
          ) : (
            <FontAwesomeIcon icon={faMinusCircle} className="text-gray-400 h-4" />
          ),
        },
      })

    }else{
      tableData.push({
        rowId: items.id,
        deletedRow: items.removedOperationId !== null,
        payload: {
          Operación: <MovementsTypeBadge operation={items.operation} />,
          Cantidad: `${items.quantity} ${translateMeasure(
            items.product.measure
          )}`,
          Fecha: `${moment(
            items.createdAt
          ).format("DD/MM/YYYY hh:mm A")}`,
          Usuario: items.movedBy ? (
            items.movedBy.displayName
          ) : (
            <FontAwesomeIcon icon={faMinusCircle} className="text-gray-400 h-4" />
          ),
        },
      })

    }
    
  }
  );

  const availableFilters:FilterOpts[] = [
    {
     name:"Operación",
     filterCode:"operation",
     format:"select",
     data:typesDisplay
    },
    {
      name:"Área",
      filterCode:"areaId",
      format:"select",
      data:areas.filter(item=>item.type==="STOCK").map(elem=>({id:elem.id, name:elem.name}))
     }
  ];

  const filterAction = (data: BasicType | null) => {
    data ? setFilter({ ...defaultFilter, ...data }) : setFilter(defaultFilter);
  };

  //-------------------------------------------------------------------------------------

  return (
    <div className="px-5 pb-16 border border-slate-300 rounded-md h-[34rem] p-5 overflow-auto scrollbar-none">      
      <GenericTable
        tableTitles={tableTitles}
        tableData={tableData}
        paginateComponent={
          <Paginate
            action={(page: number) => setFilter({ ...filter,page })}
            data={paginate}
          />
        }
        rowAction={(id: number) => {
          setModalMovement({ modalState: true, movementId: id });
        }}
        filterComponent={{availableFilters, filterAction}}
        loading={isLoading}
      />

      {modalMovement.modalState && (
        <Modal
          close={() =>
            setModalMovement({
              modalState: false,
              movementId: null,
            })
          }
          state={modalMovement.modalState}
          size="l"
        >
          <DetailMovement id={modalMovement.movementId} />
        </Modal>
      )}
    </div>
  );
};

export default Opperations;
