import { TrashIcon } from "@heroicons/react/24/outline";
import { useState, useContext, useEffect } from "react";
import AlertContainer from "../../../components/misc/AlertContainer";
import Button from "../../../components/misc/Button";
import { DetailProductContext } from "../DetailProductContainer";
import Modal from "../../../components/modals/GenericModal";
import { SubmitHandler, useForm } from "react-hook-form";
import Toggle from "../../../components/forms/Toggle";
import Input from "../../../components/forms/Input";
import GenericToggle from "../../../components/misc/GenericToggle";
import { useAppSelector } from "../../../store/hooks";
import useServer from "../../../api/useServerMain";

interface SettingProps {
  closeModal: Function;
}

const Setting = ({ closeModal }: SettingProps) => {
  const { product, deleteProduct, updateProduct, isFetching } =
    useContext(DetailProductContext);
  const { handleSubmit, control, watch, unregister, setValue } = useForm();
  const [alert, setAlert] = useState(false);

  //STOCK, MENU, ADDON, SERVICE, VARIATION, COMBO-------------------
  const [visibleForSale, setVisibleForSale] = useState(
    product?.showForSale ||
      product?.isPublicVisible ||
      product?.showWhenOutStock ||
      product?.showRemainQuantities ||
      product?.visibleOnline ||
      false
  );
  const [onSale, setOnSale] = useState(product?.onSale ?? false);
  const publicVisible = watch("isPublicVisible");
  const showForSale = watch("showForSale");
  const showOutStock = watch("showWhenOutStock");
  const showRemainQuant = watch("showRemainQuantities");
  const visibleOnline = watch("visibleOnline");
  const isWholesale = watch("isWholesale") ?? product?.isWholesale;

  const { business } = useAppSelector((state) => state.init);
  const {allowRoles: verifyRoles} = useServer();


  useEffect(() => {
    if (!onSale) {
      unregister("onSalePrice");
      setValue("onSalePrice", null);
    }

    if (
      publicVisible === false &&
      showForSale === false &&
      showOutStock === false &&
      showRemainQuant === false &&
      visibleOnline === false
    )
      setVisibleForSale(false);
  }, [
    onSale,
    publicVisible,
    showForSale,
    showOutStock,
    showRemainQuant,
    visibleOnline,
  ]);

  useEffect(() => {
    if (!visibleForSale) {
      setValue("showForSale", false);
      setValue("isPublicVisible", false);
      setValue("showWhenOutStock", false);
      setValue("showRemainQuantities", false);
      setValue("visibleOnline", false);
    }
  }, [visibleForSale]);

  //----------------------------------------------------------------

  //Asset products -------------------------------------------------
  const enableDepreciation =
    watch("enableDepreciation") ?? product?.enableDepreciation;

  //----------------------------------------------------------------

  const onSubmit: SubmitHandler<Record<string, any>> = (data) => {
    if (data.onSalePrice) {
      const onSalePrice = {
        amount: data.onSalePrice?.price,
        codeCurrency: data.onSalePrice.codeCurrency,
      };
      data = { ...data, onSalePrice };
    }
    updateProduct!(product?.id, data);
  };

const isAdmin = verifyRoles(["ADMIN"])

  return (
    <div className="border border-slate-300 rounded-md p-5 h-[34rem] relative">
      {isAdmin &&<div className="flex justify-end">
        <Button
          name="Eliminar Producto"
          icon={<TrashIcon className="h-5" />}
          color="red-500"
          textColor="red-500"
          colorHover="red-400"
          action={() => setAlert(true)}
          outline
        />
      </div>}
      <form onSubmit={handleSubmit(onSubmit)}>
        {["STOCK", "MENU", "ADDON", "SERVICE", "VARIATION", "COMBO"].includes(
          product?.type ?? ""
        ) && (
          <div className="py-5 grid grid-cols-2 items-start h-72 overflow-auto scrollbar-thin scrollbar-thumb-slate-200">
            <div>
              <GenericToggle
                currentState={visibleForSale}
                changeState={setVisibleForSale}
                title="Visible para la venta"
              />
              {visibleForSale && (
                <div className="pl-5">
                  <Toggle
                    name="isPublicVisible"
                    control={control}
                    defaultValue={product?.isPublicVisible}
                    title="Visible carta de venta"
                  />
                  {/* {wooModule && ( */}
                  <Toggle
                    name="visibleOnline"
                    control={control}
                    defaultValue={product?.visibleOnline}
                    title="Visible para internet"
                  />
                  {/* )} */}
                  <Toggle
                    name="showForSale"
                    control={control}
                    defaultValue={product?.showForSale}
                    title="Visible punto de venta"
                  />
                  <Toggle
                    name="showWhenOutStock"
                    control={control}
                    defaultValue={product?.showWhenOutStock}
                    title="Mostrar estando agotado"
                  />
                  <Toggle
                    name="showRemainQuantities"
                    control={control}
                    defaultValue={product?.showRemainQuantities}
                    title="Mostrar cantidades disponibles"
                  />
                </div>
              )}
            </div>

            <div>
              {/* {product?.type === "SERVICE" && (
                <>
                  <div>
                    <Toggle
                      name="discountPolicy"
                      title="Política de descuento"
                      control={control}
                      defaultValue={product.saleByWeight ?? false}
                    />
                    {showDiscountPolicy && (
                      <Select
                        control={control}
                        name="d"
                        data={[{ name: "Prueba", id: "Prueba" }]}
                        label="Política de descuento"
                      />
                    )}
                  </div>
                  <div>
                    <Toggle
                      name="cancelationPolicy"
                      title="Política de cancelación"
                      control={control}
                      defaultValue={product.saleByWeight ?? false}
                    />
                    {showCancelationPolicy && (
                      <Select
                        control={control}
                        name="d"
                        data={[{ name: "Prueba", id: "Prueba" }]}
                        label="Política de cancelación"
                      />
                    )}
                  </div>
                </>
              )} */}
              {product?.type === "STOCK" && (
                <Toggle
                  name="saleByWeight"
                  title="Habilitar venta por peso"
                  control={control}
                  defaultValue={product.saleByWeight ?? false}
                />
              )}
              {["STOCK", "VARIATION"].includes(product?.type ?? "") && (
                <>
                  <Toggle
                    name="isWholesale"
                    title="Venta al por mayor"
                    control={control}
                    defaultValue={product?.isWholesale}
                  />
                  {!!isWholesale && (
                    <Input
                      name="minimunWholesaleAmount"
                      type="number"
                      control={control}
                      label="Cantidad mínima"
                      defaultValue={product?.minimunWholesaleAmount}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {product?.type === "ASSET" && (
          <>
            <Toggle
              name="enableDepreciation"
              control={control}
              title="Habilitar depreciación"
              defaultValue={product.enableDepreciation}
            />
            {enableDepreciation && (
              <Input
                name="monthlyDepreciationRate"
                label={`Tasa de depreciación mensual (en ${business?.costCurrency})`}
                type="number"
                control={control}
                defaultValue={product.monthlyDepreciationRate}
              />
            )}
          </>
        )}
        <div className="flex justify-end pt-3 absolute -bottom-14 right-0">
          <Button
            color="slate-600"
            name="Actualizar"
            type="submit"
            loading={isFetching}
            disabled={isFetching}
          />
        </div>
      </form>

      {alert && (
        <Modal state={alert} close={setAlert}>
          <AlertContainer
            onAction={() =>
              deleteProduct && deleteProduct(product?.id ?? null, closeModal)
            }
            onCancel={() => setAlert(false)}
            title={`Eliminar ${product?.name}`}
            text="Seguro que desea eliminar este producto?"
          />
        </Modal>
      )}
    </div>
  );
};

export default Setting;
