import React, { useState, useEffect, useContext } from "react";
import Checkbox from "../../../components/forms/Checkbox";
import {
  BasicNomenclator,
  ProductInterface,
} from "../../../interfaces/ServerInterfaces";
import useServerProduct from "../../../api/useServerProducts";
import SpinnerLoading from "../../../components/misc/SpinnerLoading";
import Button from "../../../components/misc/Button";
import { DetailProductContext } from "../DetailProductContainer";
import TextArea from "../../../components/forms/TextArea";
import { SubmitHandler, useForm } from "react-hook-form";
import GenericTable from "../../../components/misc/GenericTable";
import { Plus } from "heroicons-react";
import { TableActions } from "../../../components/misc/MultipleActBtn";
import Modal from "../../../components/misc/GenericModal";
import CustomRadio, { CustomRadioData } from "../../../components/forms/CustomRadio";
import EmptyList from "../../../components/misc/EmptyList";
import SearchComponent from "../../../components/misc/SearchComponent";
import Input from "../../../components/forms/Input";
import { toast } from "react-toastify";
import { translateMeasure } from "../../../utils/translate";
import ProductTypeBadge from "../../../components/misc/badges/ProductTypeBadge";

interface Addon {
  product: ProductInterface | null;
}

const Addon = () => {
  const { getAllProducts, allProducts, outLoading } = useServerProduct();
  const { product, updateProduct } = useContext(DetailProductContext);
  const [selected, setSelected] = useState<BasicNomenclator[]>([]);

  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    getAllProducts({ type: "ADDON", all_data: true });
    setSelected(product?.availableAddons.map((item) => item) ?? []);
  }, []);

  //Checkbox Management-----------------------------------------------------------------------------------
  const checkData: BasicNomenclator[] = [];
  allProducts.map((item) =>
    checkData.push({
      id: item.id,
      name: item.name,
    })
  );

  //-------------------------------------------------------------------------------------------------------

  const { control, handleSubmit } = useForm();

  const onSubmit: SubmitHandler<Record<string, string | number | boolean>> = (
    data
  ) => {
    const { elaborationSteps } = data;

    updateProduct &&
      updateProduct(product?.id, {
        availableAddons: selected.map((item) => item.id),
        elaborationSteps,
      });
  };


  const tableTitle = ["Nombre", "Descripción", "Disponibilidad", "Reservable"];

  const costActions: TableActions[] = [
    {
      title: "Añadir recurso",
      icon: <Plus className="h-5" />,
      action: () => setAddModal(true),
    },
  ];

  if (outLoading)
    return (
      <div className="h-[34rem]">
        <SpinnerLoading />
      </div>
    );
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-stretch"
      >
        <div className="border border-slate-300 rounded-md p-5 h-[34rem] overflow-auto scrollbar-thin scrollbar-thumb-gray-200">
        <GenericTable
          tableData={[]}
          tableTitles={tableTitle}
          actions={costActions}
          rowAction={()=>{}}
        />
        </div>

        

        <div className="flex justify-end py-2 ">
          <Button name="Actualizar" color="slate-600" type="submit" />
        </div>
      </form>

      {addModal && (
        <Modal state={addModal} close={setAddModal} size="m">
          <NewElement addElement={()=>{}} />
        </Modal>
      )}
    </>
  );
  // return (
  //   <>
  //     <form
  //       onSubmit={handleSubmit(onSubmit)}
  //       className="flex flex-col items-stretch h-full"
  //     >
  //       <div className="h-96 overflow-auto scrollbar-thin scrollbar-thumb-gray-200">
  //         <TextArea
  //           name="elaborationSteps"
  //           control={control}
  //           label="Pasos de elaboración"
  //           defaultValue={product?.elaborationSteps}
  //         />
  //       </div>

  //       <div className="h-96 overflow-auto scrollbar-thin scrollbar-thumb-gray-200">
  //         <Checkbox
  //           data={checkData}
  //           selected={selected}
  //           setSelected={setSelected}
  //         />
  //       </div>

  //       <div className="flex justify-end py-2 ">
  //         <Button name="Actualizar" color="slate-600" type="submit" />
  //       </div>
  //     </form>
  //   </>
  // );
};

interface NewElement {
  addElement: Function;
}
const NewElement = ({ addElement }: NewElement) => {
  const { control, handleSubmit, getValues, setError, watch, clearErrors } =
    useForm();
  const { getAllProducts, allProducts, outLoading } = useServerProduct();
  const [search, setSearch] = useState<string | null>(null);

  const onSubmit: SubmitHandler<Record<string, string | number | boolean>> = (
    data
  ) => {
    const supplyProductId = getValues("supplyProductId");
    if (!supplyProductId) {
      toast.error("Busque y seleccione un producto", { autoClose: 5000 });
    } else {
      addElement({
        ...allProducts.find((prod) => prod.id === supplyProductId),
        quantity: data.quantity,
      });
    }
  };

  //Data for list product -----------------------------------------------------------------------
  useEffect(() => {
    search &&
      getAllProducts({
        type: "RAW,MANUFACTURED,STOCK,WASTE",
        search,
        all_data: true,
      });
  }, [search]);

  const data: CustomRadioData[] = [];
  search &&
    allProducts.map((product) =>
      data.push({
        value: product.id,
        img:
          product.images[0]?.src ??
          require("../../../assets/image-default.jpg"),
        name: product.name,
        elements: {
          type: <ProductTypeBadge type={product.type} />,
          measure: translateMeasure(product.measure),
          //cost: currency?.format(product.averageCost),
          input:
            watch("supplyProductId") === product.id ? (
              <Input
                name="quantity"
                type="number"
                placeholder="Cantidad (*)"
                control={control}
                rules={{ required: "Campo requerido" }}
              />
            ) : (
              ""
            ),
        },
      })
    );
  //---------------------------------------------------------------------------------------------

  return (
    <>
      <SearchComponent findAction={setSearch} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 pr-2 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-100">
          {outLoading ? (
            <SpinnerLoading text="Buscando producto" />
          ) : data.length === 0 && !search ? (
            <EmptyList
              title="Buscar Producto"
              subTitle="Inserte un criterio de búsqueda"
            />
          ) : data.length === 0 && search ? (
            <EmptyList
              title="Producto no encontrado"
              subTitle="Inserte otro criterio de búsqueda"
            />
          ) : (
            <CustomRadio
              data={data}
              name="supplyProductId"
              control={control}
              action={() => clearErrors()}
            />
          )}
        </div>
        <div className="flex p-2 justify-end">
          <Button name="Agregar" type="submit" color="slate-600" />
        </div>
      </form>
    </>
  );
};

export default Addon;
